import React, {useEffect, useRef, useState} from "react"
import PropTypes from "prop-types"
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap"
import {connect} from "react-redux"
import { useLocation, withRouter } from "react-router-dom"

import {registerLocale, setDefaultLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import ptBR from "date-fns/locale/pt-BR"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
    editarGrupoEstoque,
    navegarEditarGrupoEstoque, navegarListarEstoque, relatorioExecutar,
    removerGrupoEstoque
} from "../../store/auth/profile/actions"
import usePermission from "helpers/permission/usePermission"
import AlertaSemVinculo from "../../components/Common/alerta-sem-vinculo"

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

import { getCompanies, getCompaniesValuesByOptions, getCompanyOption, getDatasPeloPeriodo } from "../../helpers/utils"
import FormFilterCompany from "../../components/Common/form-filter-company"
import ReqEstoqueTable from "./req-estoque-table"
import FormFilterSelect from "../../components/Common/form-filter-select"
import { navegarBreadCrumb } from "../../store/navigator/actions"
import FormFilterPeriod from "../../components/Common/form-filter-period"
import moment from "moment/moment"
import { Filters } from "../../common/data/definitions"
import { withTranslation } from "react-i18next"

const RequisicaoEstoqueLista = props => {

    const ref = useRef();

    const [filters, setFilters] = useState(null)

    const [companies, setCompanies] = useState([])
    const [companiesOption, setCompaniesOption] = useState([])
    const [status, setStatus] = useState("")
    const [periods, setPeriods] = useState([])
    const [period, setPeriod] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [funcionario, setFuncionario] = useState("")

    const estoquePermissionList = usePermission("requisicaoEstoque_listar")

    const possuiVinculo = (props.user?.vinculos || []).some(vinculo => ["A", "T"].includes(vinculo.vinculo_status))
    const location = useLocation();

    useEffect(() => {
        const tmpPeriods = []
        for (let i = 0; i <= 11; i++) {
            tmpPeriods.push({
                label: moment().subtract(i, "month").format("MMMM/YY"),
                value: moment().subtract(i, "month").format("YYYY-MM-DD")
            })
        }

        tmpPeriods.unshift({label: "Selecione", value: null})

        let primeiroPeriodo = tmpPeriods[0].value
        setPeriod(primeiroPeriodo)

        tmpPeriods.push({label: "Personalizado", value: "Personalizado"})
        setPeriods(tmpPeriods)

        if(props.dashboardFilters.companiesSelected) setCompanies(props.dashboardFilters.companiesSelected)

        if (possuiVinculo) {
            populateFiltros(props.dashboardFilters.companiesSelected)
        }
        props.navegarBreadCrumb({title: `breadcrumb.stock.req`, link: location.pathname})
    }, [])

    useEffect(() => {
        if(props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador && Array.isArray(props.empresas)) {
            let companiesOptions = props.empresas.map(empresa => getCompanyOption(empresa));
            setCompaniesOption(companiesOptions)
        } else {
            let companiesOptions = getCompanies(props.user)
            setCompaniesOption(companiesOptions)
        }
        return () => {
            setCompaniesOption([])
        }
    }, [props.user, props.empresas])

    const populateFiltros = (companiesSelected) => {
        const filters = []

        if(companiesSelected) {
            filters.push({id: 'idEmpresa', value: getCompaniesValuesByOptions(companiesSelected)})
        } else if(companies) {
            filters.push({id: 'idEmpresa', value: getCompaniesValuesByOptions(companies)})
        }

        if (status) {
            filters.push({id: 'status', value: status})
        }

        if (funcionario){
            filters.push({id: 'funcionario.nome_completo', value: funcionario, condition: Filters.contains})
        }

        let datasPeloPeriodo = getDatasPeloPeriodo(period, startDate, endDate)
        if(datasPeloPeriodo) {
            filters.push({id: 'data', value: datasPeloPeriodo.inicio, condition: Filters.greaterThanOrEqualTo})
            filters.push({id: 'data', value: datasPeloPeriodo.fim, condition: Filters.lessThanOrEqualTo})
        }

        setFilters(filters)
    }

    const pesquisar = () => {
        populateFiltros()
        setTimeout(() => ref?.current?.search(), 300)
    }

    const printPDF = () => {
        let reportName = 'requisicao_estoque';
        props.relatorioExecutar({
            company: getCompaniesValuesByOptions(companies),
            status: status,
            dataInicial: startDate,
            dataFinal: endDate,
            reportName
        });
    }

    if (
        !props.user ||
        !props.user.vinculos.length ||
        !(props.user.vinculos || []).filter(e =>
            ["A", "T"].find(status => status == e.vinculo_status)
        ).length
    ) {
        return (
            <AlertaSemVinculo/>
        )
    }

    const onPickStartDate = date => {
        setStartDate(date)
        setEndDate(date)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="breadcrumb.stock.req"/>

                    <Row>
                        <Col lg="12">
                            <div>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title mb-3">{props.t('req-estoque.title')}</h4>

                                        <Form>
                                            <Row>
                                                <div className="col-md-3 col-sm-12">
                                                    <FormFilterCompany
                                                      attribute="companies"
                                                      value={companies}
                                                      description="Empresa(s)"
                                                      setValue={value => setCompanies(value)}
                                                      options={companiesOption}
                                                      multiple={true}
                                                      hasSelectAll={!(props.user?.usuario_info && props.user?.usuario_info?.perfil?.administrador)}
                                                    />
                                                </div>

                                                <FormFilterPeriod
                                                  periodValue={period ? period: ''}
                                                  periodChangeHandeler={e => setPeriod(e)}
                                                  options={periods}
                                                  startDateValue={startDate}
                                                  startDateChangeHandeler={e => onPickStartDate(e)}
                                                  endDateValue={endDate}
                                                  endDateChangeHandeler={e => setEndDate(e)}
                                                  t={props.t}
                                                />

                                                <div className="col-xl col-sm-6">
                                                    <FormFilterSelect
                                                      attribute="statusReq"
                                                      value={status}
                                                      description="Status"
                                                      changeHandeler={value => setStatus(value)}
                                                      options={[
                                                          { value: "", label: props.t('req-estoque.status.all'), defaultValue: true },
                                                          { value: "G", label: props.t('req-estoque.status.G') },
                                                          { value: "E", label: props.t('req-estoque.status.E') },
                                                          { value: "A", label: props.t('req-estoque.status.A') },
                                                          { value: "P", label: props.t('req-estoque.status.P') },
                                                          { value: "R", label: props.t('req-estoque.status.R') }
                                                      ]}
                                                    />
                                                </div>

                                                <div className="col-xl col-sm-6">
                                                    <FormGroup className="mt-3 mb-0">
                                                        <Label>Funcionário</Label>
                                                        <Input
                                                          id="usuarioVenda"
                                                          name="usuarioVenda"
                                                          type="text"
                                                          className="form-control"
                                                          value={funcionario}
                                                          onChange={e => setFuncionario(e.target.value)}>
                                                        </Input>
                                                    </FormGroup>
                                                </div>

                                                <div className="col col-auto align-self-start mt-md-4 pt-1 row">
                                                    <div className="mt-3 col col-auto">
                                                        <Button color="primary" className="w-md"
                                                                onClick={e => pesquisar()}
                                                                disabled={!estoquePermissionList}>
                                                            Buscar
                                                        </Button>
                                                    </div>


                                                    {!props.executaRelatorioLoading &&
                                                      <div className="mt-3 col col-auto">
                                                          <Button type="button" color="light" onClick={e => printPDF()}>
                                                              <i
                                                                className="bx bx-download align-middle font-size-16 me-2"></i>
                                                              PDF
                                                          </Button>
                                                      </div>
                                                    }
                                                </div>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>

                                {filters && <Card>
                                    <CardBody>
                                        <ReqEstoqueTable ref={ref} filters={filters}
                                                         history={props.history}
                                                         usePermissionRemove={false}
                                                         usePermissionEdit={false}
                                                         navegarListarEstoque={null}
                                                         navegarEditarGrupoEstoque={null}
                                                         removerGrupoEstoque={null}
                                                         t={props.t}>
                                        </ReqEstoqueTable>
                                    </CardBody>
                                </Card>}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

RequisicaoEstoqueLista.propTypes = {
    grupoEstoqueRemovido: PropTypes.any,
    removerGrupoEstoque: PropTypes.func,
    editarGrupoEstoque: PropTypes.func,
    relatorioExecutar: PropTypes.func,
    navegarEditarGrupoEstoque: PropTypes.func,
    navegarListarEstoque: PropTypes.func,
}

const mapStatetoProps = state => {
    const { grupoEstoqueRemovido, empresas, empresasLoading } = state.Profile
    const { user, dashboardFilters } = state.Login
    return { user, grupoEstoqueRemovido, dashboardFilters, empresas, empresasLoading }
}

export default withTranslation()(connect(mapStatetoProps, {
    navegarEditarGrupoEstoque,
    navegarListarEstoque,
    removerGrupoEstoque,
    editarGrupoEstoque,
    relatorioExecutar,
    navegarBreadCrumb
})(withRouter(RequisicaoEstoqueLista)))
