import PropTypes from "prop-types"
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import {
  apiBuscarRequisicaoEstoque, apiBuscarRequisicaoEstoqueItemPorRequisicao
} from "../../helpers/backend_helper"
import ListDataTable from "../../components/Common/list-data-table"
import { describeDateTime, removeHour } from "../../helpers/utils"
import "react-datepicker/dist/react-datepicker.css"
import ptBR from "date-fns/locale/pt-BR"
import { ptBR as ptBRDatePickers } from "@mui/x-date-pickers"
import { registerLocale, setDefaultLocale } from "react-datepicker"
import { Box, TextField } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import AddIcon from "@mui/icons-material/Add"
import MinusIcon from "@mui/icons-material/Remove"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

const ptBRLocale = ptBRDatePickers.components.MuiLocalizationProvider.defaultProps.localeText

registerLocale("ptBR", ptBR)
setDefaultLocale("ptBR")

const ReqEstoqueTable = forwardRef((props, refFather) => {

  const ref = useRef()
  const queryClient = new QueryClient()
  const {
    filters,
    columns,
    colItens,
    t
  } = props
  const [data, setData] = useState([])

  useImperativeHandle(refFather, () => ({
    search() {
      ref?.current?.search()
    }
  }))


  const describeStatus = (status) => {
    if (status) {
      switch (status) {
        case "G":
          return t('req-estoque.status.G')
        case "E":
          return t('req-estoque.status.E')
        case "A":
          return t('req-estoque.status.A')
        case "P":
          return t('req-estoque.status.P')
        case "R":
          return t('req-estoque.status.R')
      }
    }
    return t('req-estoque.status.empty')
  }


  const colunasItens = useMemo(() => colItens ||
    [
      {
        header: t('req-estoque-item.idID'), accessorKey: "idId", size: 40, enableHiding: false,
        muiTableBodyCellProps: {
          align: "left"
        }
      },
      { header: t('req-estoque-item.UUID'), accessorKey: "UUID", size: 240, id: "UUID" },
      { header: t('req-estoque-item.reqId'), accessorKey: "est_req_id", size: 100, id: "est_req_id" },
      { header: t('req-estoque-item.codigo'), accessorKey: "codigo", size: 60, id: "codigo" },
      { header: t('req-estoque-item.qtd'), accessorKey: "qtd", size: 120, id: "qtd",
        Cell: ({cell}) => parseFloat(cell.getValue() || 0).toLocaleString("pt-br", {maximumFractionDigits: 3})
      },
      {
        header: t('req-estoque-item.custo'), accessorKey: "custo", size: 80, id: "custo",
        Cell: ({ cell }) => (<Box sx={{ display: "flex", alignItems: "right", gap: "1rem" }}>
          {parseFloat(cell.getValue()).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL"
          })}
        </Box>)
      },
      { header: t('req-estoque-item.qtdAtendida'), accessorKey: "qtd_atendida", size: 80, id: "qtd_atendida",
        Cell: ({cell}) => parseFloat(cell.getValue() || 0).toLocaleString("pt-br", {maximumFractionDigits: 3})
      },
      { header: t('req-estoque-item.estAtual'), accessorKey: "est_atual_loja", size: 80, id: "est_atual_loja",
        Cell: ({cell}) => parseFloat(cell.getValue() || 0).toLocaleString("pt-br", {maximumFractionDigits: 2})
      }
    ], []
  )

  const colunas = useMemo(() => columns ||
    [
      {
        header: t('req-estoque.id'), accessorKey: "id", size: 80, enableHiding: false,
        muiTableBodyCellProps: {
          align: "left"
        }
      },
      { header: t('req-estoque.empresa'), accessorKey: "idEmpresa", size: 120 },
      { header: t('req-estoque.codigo'), accessorKey: "idId", size: 120 },
      { header: t('req-estoque.loja.origem'), accessorKey: "loja_origem", size: 150, enableColumnFilter: false },
      { header: t('req-estoque.loja.destino'), accessorKey: "loja_destino", size: 150, enableColumnFilter: false },

      {
        header: t('req-estoque.data'),
        accessorKey: "data",
        size: 150,
        Cell: ({ cell }) => describeDateTime(cell?.getValue()),
        sortingFn: "datetime",
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR} localeText={ptBRLocale}>
            <DatePicker
              onChange={(newValue) => {
                if (newValue) {
                  newValue = removeHour(newValue, false)
                }
                column.setFilterValue(newValue)
              }}
              inputFormat="dd/MM/yyyy"
              componentsProps={{
                actionBar: {
                  actions: ["accept", "clear"]
                }
              }}
              value={column.getFilterValue() ? new Date(column.getFilterValue()) : null}
              views={["year", "month", "day"]}
              renderInput={(props) => <TextField {...props} variant="standard" />}
            />
          </LocalizationProvider>
        )
      },
      { header: t('req-estoque.func'), accessorKey: "funcionario.nome_completo", size: 150, enableColumnFilter: false },
      {
        header: t('req-estoque.status'), accessorKey: "status", size: 150, enableColumnFilter: false,
        Cell: ({ cell }) => describeStatus(cell?.getValue()).toUpperCase(),
        filterSelectOptions: [
          { text: t('req-estoque.status.G'), value: "G" },
          { text: t('req-estoque.status.E'), value: "E" },
          { text: t('req-estoque.status.A'), value: "A" },
          { text: t('req-estoque.status.P'), value: "P" },
          { text: t('req-estoque.status.R'), value: "R" }
        ],
        filterVariant: "multi-select"
      },
      {
        header: t('req-estoque.status.data'),
        accessorKey: "status_data",
        size: 150,
        Cell: ({ cell }) => describeDateTime(cell?.getValue()),
        sortingFn: "datetime",
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR} localeText={ptBRLocale}>
            <DatePicker
              onChange={(newValue) => {
                if (newValue) {
                  newValue = removeHour(newValue, false)
                }
                column.setFilterValue(newValue)
              }}
              inputFormat="dd/MM/yyyy"
              componentsProps={{
                actionBar: {
                  actions: ["accept", "clear"]
                }
              }}
              value={column.getFilterValue() ? new Date(column.getFilterValue()) : null}
              views={["year", "month", "day"]}
              renderInput={(props) => <TextField {...props} variant="standard" />}
            />
          </LocalizationProvider>
        )
      },
      { header: t('req-estoque.uuid'), accessorKey: "uuid", size: 120 },
      { header: t('req-estoque.func.alt'), accessorKey: "altFuncionario.nome_completo", size: 150, enableColumnFilter: false },
      {
        header: t('req-estoque.ult.alt'),
        accessorKey: "altdata",
        size: 150,
        Cell: ({ cell }) => describeDateTime(cell?.getValue()),
        sortingFn: "datetime",
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR} localeText={ptBRLocale}>
            <DatePicker
              onChange={(newValue) => {
                if (newValue) {
                  newValue = removeHour(newValue, false)
                }
                column.setFilterValue(newValue)
              }}
              inputFormat="dd/MM/yyyy"
              componentsProps={{
                actionBar: {
                  actions: ["accept", "clear"]
                }
              }}
              value={column.getFilterValue() ? new Date(column.getFilterValue()) : null}
              views={["year", "month", "day"]}
              renderInput={(props) => <TextField {...props} variant="standard" />}
            />
          </LocalizationProvider>
        )
      },
      { header: t('req-estoque.central.uuid'), accessorKey: "central_uuid", size: 150 },
      { header: t('req-estoque.central.id'), accessorKey: "central_id", size: 120 }
    ], [])

  const ReqItems = ({ row }) => {
    const [dataItems, setDataItems] = useState([]);

    const itemsFilters = []
    itemsFilters.push({ id: "idEmpresa", value: [row?.row.original.idEmpresa] })
    itemsFilters.push({ id: "est_req_uuid", value: row?.row.original.uuid, condition: "eq" })

    const searchFn = useMemo(
      () => (params) => {
        params.filters = JSON.stringify(itemsFilters)
        return apiBuscarRequisicaoEstoqueItemPorRequisicao(params)
      },
      []
    );

    return (
      <ListDataTable
        searchFn={searchFn}
        columns={colunasItens}
        data={dataItems}
        setData={setDataItems}
        defaultSorting={[
          {
            id: "idId",
            desc: true,
          },
        ]}
        showColumnFilters={false}
        enableRowActions={false}
        enableExpandAll={false}
        enableColumnsActions={false}
        enableTopToolbar={false}
      />
    );
  };

  return (
    <React.Fragment>
      <QueryClientProvider client={queryClient}>
        <ListDataTable
          ref={ref}
          searchFn={params => apiBuscarRequisicaoEstoque(params)}
          advancedFilters={filters}
          columns={colunas}
          data={data}
          setData={setData}
          columnVisibility={{
            uuid: false,
            altfunc: false,
            altdata: false,
            central_uuid: false,
            central_id: false
          }}
          defaultSorting={[{
            id: "id",
            desc: true
          }]}
          enableRowActions={false}
          enableExpandAll={false}
          muiExpandButtonProps={(row) => ({
            children: row.getIsExpanded() ? <MinusIcon></MinusIcon> : <AddIcon></AddIcon>
          })}
          DetailPanel={ReqItems}
        />
      </QueryClientProvider>
    </React.Fragment>
  )
})

export default ReqEstoqueTable

ReqEstoqueTable.propTypes = {
  filters: PropTypes.array,
  columns: PropTypes.array,
  usePermissionRemove: PropTypes.bool,
  usePermissionEdit: PropTypes.bool,
  history: PropTypes.any,
  navegarEditarGrupoEstoque: PropTypes.func,
  navegarListarEstoque: PropTypes.func,
  removerGrupoEstoque: PropTypes.func,
  t: PropTypes.func
}
