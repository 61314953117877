import PropTypes from "prop-types"
import React from "react"
import { Alert, Col, Container, Input, Row } from "reactstrap"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// action
import { apiError, registerUser, registerUserFailed, socialLogin, socialLoginToken } from "../../store/actions"

// Redux
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"

// import images
import ReCAPTCHA from "react-google-recaptcha"
import { facebook, google, recaptcha } from "../../config"
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google"
import InputMask from "react-input-mask"
import PasswordStrengthBar from "react-password-strength-bar"
import Loader from "react-loader-spinner"

import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import CarouselPage from "./CarouselPage"

class Register extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      password: "",
      recaptchaValidated: false,
      passwordScore: 0,
      step: 0
    }
  }

  handleValidSubmit(event, values) {
    if (this.state.step === 0) {
      this.setState({ step: 1 })
    } else {
      values.recaptchaToken = this.state.recaptchaToken
      this.props.registerUser(values, this.props.history)
    }
  }

  onRecaptchaChange = response => {
    console.debug("[onRecaptchaChange]", { response })

    this.setState(prevState => ({ recaptchaValidated: true, recaptchaToken: response }))
  }

  signIn = (res, type) => {
    const { socialLogin } = this.props
    if (type === "google" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        idToken: res.tokenId
      }
      socialLogin(postData, this.props.history, type)
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId
      }
      socialLogin(postData, this.props.history, type)
    }
  }

  facebookResponse = response => {
    this.signIn(response, "facebook")
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.socialLoginPayload !== this.props.socialLoginTokenObj && this.props.socialLoginTokenObj) {
      this.signIn(this.props.socialLoginTokenObj, "google")
    }
  }

  googleResponse = async response => {
    const { socialLoginToken } = this.props
    socialLoginToken({token: response.credential})
  }

  onChangeScore = e => this.setState(prevState => ({ passwordScore: e }))

  componentDidMount() {
    this.props.registerUserFailed("")
    this.setState({ step: 0 })
  }

  render() {
    // handleValidSubmit
    const { password, step } = this.state

    return this.props.loading ? (
      <div className="loader-container">
        <Loader type="ThreeDots" color="#556EE6" height={ 80 } width={ 80 } />
      </div>
    ) : (
      <React.Fragment>
        <div>
          <Container fluid className="p-0">
            <Row className="g-0">
              <CarouselPage />

              <Col xl={ 4 }>
                <div className="auth-full-page-content px-md-5 px-4 py-3">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-4 mb-md-5">
                        <Link to="dashboard" className="d-block auth-logo">
                          <img
                            src={ logodark }
                            alt=""
                            height="18"
                            className="auth-logo-dark"
                          />
                          <img
                            src={ logolight }
                            alt=""
                            height="18"
                            className="auth-logo-light"
                          />
                        </Link>
                      </div>
                      <div className="my-auto">
                        <div>
                          <h5 className="text-primary">Cadastro</h5>
                          <p>
                            Preencha o formulário para realizar seu cadastro.
                          </p>
                        </div>

                        <div className="mt-4">
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={ (e, v) => {
                              this.handleValidSubmit(e, v)
                            } }
                          >
                            { this.props.user && this.props.user ? (
                              <Alert color="success">
                                Cadastro realizado com sucesso!
                              </Alert>
                            ) : null }
                            { this.props.registrationError &&
                            this.props.registrationError ? (
                              <Alert color="danger">
                                { this.props.registrationError }
                              </Alert>
                            ) : null }

                            <div
                              style={ { display: step !== 0 ? "none" : "block" } }
                            >
                              <div className="form-group">
                                <AvField
                                  name="nome_completo"
                                  label="Nome Completo"
                                  className="form-control"
                                  placeholder="Digite o nome completo"
                                  type="text"
                                  required={ step === 0 }
                                  errorMessage="Campo obrigatório."
                                />
                              </div>

                              <div className="form-group">
                                <AvField
                                  name="email"
                                  label="E-mail"
                                  type="email"
                                  required={ step === 0 }
                                  errorMessage="Campo obrigatório."
                                  placeholder="Digite o e-mail"
                                />
                              </div>

                              <div className="form-group">
                                <AvField
                                  name="telefone"
                                  label="Telefone"
                                  className="form-control"
                                  placeholder="Digite o telefone"
                                  type="text"
                                  required={ step === 0 }
                                  errorMessage="Campo obrigatório."
                                  mask="(99) 9999-99999"
                                  maskChar=""
                                  tag={ [Input, InputMask] }
                                />
                              </div>

                              <div className="mt-4">
                                <button
                                  className="btn btn-primary btn-block waves-effect waves-light"
                                  type="submit"
                                >
                                  Prosseguir
                                </button>
                              </div>

                              <div>
                                <p className="mb-0">
                                  Ao se cadastrar você está aceitando os nossos{ " " }
                                  <a href="#" className="text-primary">
                                    Termos de Uso
                                  </a>
                                </p>
                              </div>

                              <div className="mt-4 text-center">
                                <h5 className="font-size-14 mb-3">
                                  Cadastrar com
                                </h5>

                                <ul className="list-inline">
                                  <li className="list-inline-item">
                                    <GoogleOAuthProvider clientId={google.CLIENT_ID}>
                                      <GoogleLogin
                                        onSuccess={this.googleResponse}
                                        auto_select={false}
                                        text={"continue_with"}
                                        context="signin"
                                        prompt="select_account"
                                        use_fedcm_for_prompt={true}
                                      />
                                    </GoogleOAuthProvider>
                                  </li>
                                </ul>
                              </div>

                              <div className="mt-5 text-center">
                                <p>
                                  Você já tem uma conta ?{ " " }
                                  <Link
                                    to="/login"
                                    className="font-weight-medium text-primary"
                                  >
                                    { " " }
                                    Acessar
                                  </Link>{ " " }
                                </p>
                              </div>
                            </div>

                            <div
                              style={ { display: step !== 1 ? "none" : "block" } }
                            >
                              <div className="form-group">
                                <AvField
                                  name="senha"
                                  label="Senha"
                                  type="password"
                                  required={ step === 1 }
                                  errorMessage="Campo obrigatório."
                                  placeholder="Digite a senha"
                                  onChange={ e =>
                                    this.setState({ password: e.target.value })
                                  }
                                />

                                <PasswordStrengthBar
                                  password={ password }
                                  onChangeScore={ this.onChangeScore }
                                  scoreWords={ [
                                    "Fraca",
                                    "Fraca",
                                    "Razoável",
                                    "Boa",
                                    "Forte"
                                  ] }
                                  shortScoreWord="Muito curta"
                                />
                              </div>

                              <div className="form-group">
                                <AvField
                                  name="repetir_senha"
                                  label="Repetir Senha"
                                  type="password"
                                  required={ step === 1 }
                                  errorMessage="Campo obrigatório."
                                  placeholder="Digite a senha novamente"
                                  validate={ { match: { value: "senha" } } }
                                />
                              </div>

                              <div className="my-2">
                                <ReCAPTCHA
                                  sitekey={ recaptcha.siteKey }
                                  onChange={ this.onRecaptchaChange }
                                />
                              </div>

                              { this.state.passwordScore < 3 && (
                                <div
                                  className="alert alert-warning fade show"
                                  role="alert"
                                >
                                  O campo senha precisa possuir uma combinação
                                  de ao menos 6 caracteres que contenha uma
                                  letra maíscula, uma letra minúscula e um
                                  número.
                                </div>
                              ) }

                              { !this.state.recaptchaValidated && (
                                <div
                                  className="alert alert-warning fade show"
                                  role="alert"
                                >
                                  Você precisa completar o "Não sou um robô"
                                  para continuar.
                                </div>
                              ) }

                              <div className="mt-4">
                                <button
                                  className="btn btn-primary btn-block waves-effect waves-light"
                                  type="submit"
                                  disabled={
                                    !this.state.recaptchaValidated ||
                                    this.state.passwordScore < 3
                                  }
                                >
                                  Enviar
                                </button>

                                <button
                                  className="btn btn-outline-warning btn-block waves-effect waves-light"
                                  type="button"
                                  onClick={ e => this.setState({ step: 0 }) }
                                >
                                  Voltar
                                </button>
                              </div>
                            </div>
                          </AvForm>
                        </div>
                      </div>

                      <div className="mt-4 mt-md-5 text-center">
                        <p className="mb-0">
                          © { new Date().getFullYear() } Inforangra.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
  history: PropTypes.object,
  socialLogin: PropTypes.func,
  socialLoginToken: PropTypes.func,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  const { socialLoginTokenLoading, socialLoginTokenObj } = state.Login
  return { user, registrationError, loading, socialLoginTokenLoading, socialLoginTokenObj }
}

export default withRouter(
  connect(mapStatetoProps, {
    registerUser,
    apiError,
    registerUserFailed,
    socialLogin,
    socialLoginToken
  })(Register)
)
