import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Alert, Col, Container, Row } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import ReactCodeInput from "react-verification-code-input"
import PasswordStrengthBar from "react-password-strength-bar"

import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import CarouselPage from "./CarouselPage"
import ReCAPTCHA from "react-google-recaptcha"
import { recaptcha } from "../../config"
import Loader from "react-loader-spinner"
import { userForgetPasswordError, userForgetPasswordSuccess } from "../../store/auth/forgetpwd/actions"

const ForgetPasswordPage = props => {
  function handleValidSubmit(event, values) {
    props.userForgetPassword(
      {
        ...values,
        ...{ code },
        recaptchaToken: recaptchaToken
      },
      props.history
    )
  }

  const [code, setCode] = useState("")
  const [passwordScore, setPasswordScore] = useState(0)
  const [password, setPassword] = useState("")
  const [recaptchaValidated, setRecaptchaValidated] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState(null)

  const onChangeScore = e => setPasswordScore(e)

  const onRecaptchaChange = response => {
    setRecaptchaToken(response)
    setRecaptchaValidated(true)
  }

  useEffect(() => {
    setRecaptchaValidated(false)
    props.userForgetPasswordError()
    props.userForgetPasswordSuccess({})
  }, [])

  return props.loading ? (
    <div className="loader-container">
      <Loader type="ThreeDots" color="#556EE6" height={ 80 } width={ 80 } />
    </div>
  ) : (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={ 4 }>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <a href="index.html" className="d-block auth-logo">
                        <img
                          src={ logodark }
                          alt=""
                          height="18"
                          className="auth-logo-dark"
                        />
                        <img
                          src={ logolight }
                          alt=""
                          height="18"
                          className="auth-logo-light"
                        />
                      </a>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Recuperar Senha</h5>
                        <p className="text-muted">
                          Informe seu e-mail para realizar a recuperação da
                          senha
                        </p>
                      </div>

                      <div className="mt-4">
                        { props.forgetError && props.forgetError ? (
                          <Alert color="danger" style={ { marginTop: "13px" } }>
                            { props.forgetError }
                          </Alert>
                        ) : null }
                        { props.forgetSuccessMsg ? (
                          <Alert color="success" style={ { marginTop: "13px" } }>
                            { props.forgetSuccessMsg }
                          </Alert>
                        ) : null }
                        <AvForm
                          className="form-horizontal mt-4"
                          onValidSubmit={ (e, v) => handleValidSubmit(e, v) }
                        >
                          { !props.forgetStep && (
                            <div className="form-group">
                              <AvField
                                name="email"
                                label="E-mail"
                                className="form-control"
                                placeholder="Digite seu e-mail"
                                type="email"
                                required
                                errorMessage="Campo obrigatório."
                              />

                              <div className="mt-2">
                                <ReCAPTCHA
                                  sitekey={ recaptcha.siteKey }
                                  onChange={ onRecaptchaChange }
                                  onErrored={ e => setRecaptchaValidated(false) }
                                  onExpired={ e => setRecaptchaValidated(false) }
                                />
                              </div>
                            </div>
                          ) }

                          { props.forgetStep && props.forgetStep === "code" && (
                            <ReactCodeInput
                              className="mb-4 mx-auto"
                              onChange={ val => setCode(val) }
                            />
                          ) }

                          { props.forgetStep && props.forgetStep === "password" && (
                            <div className="form-group">
                              <AvField
                                name="senha"
                                label="Senha"
                                type="password"
                                required
                                errorMessage="Campo obrigatório."
                                placeholder="Digite a senha"
                                onChange={ e => setPassword(e.target.value) }
                              />

                              <PasswordStrengthBar
                                password={ password }
                                onChangeScore={ onChangeScore }
                                scoreWords={ [
                                  "Fraca",
                                  "Fraca",
                                  "Razoável",
                                  "Boa",
                                  "Forte"
                                ] }
                                shortScoreWord="Muito curta"
                              />

                              <AvField
                                name="repetir_senha"
                                label="Repetir Senha"
                                type="password"
                                required
                                errorMessage="Campo obrigatório."
                                placeholder="Digite a senha novamente"
                                validate={ { match: { value: "senha" } } }
                              />

                              { passwordScore < 3 && (
                                <div
                                  className="alert alert-warning fade show"
                                  role="alert"
                                >
                                  O campo senha precisa possuir uma combinação
                                  de ao menos 6 caracteres que contenha uma
                                  letra maíscula, uma letra minúscula e um
                                  número.
                                </div>
                              ) }
                            </div>
                          ) }

                          { !props.forgetStep && !recaptchaValidated && (
                            <div
                              className="alert alert-warning fade show"
                              role="alert"
                            >
                              Você precisa completar o "Não sou um robô" para
                              continuar.
                            </div>
                          ) }

                          <Row className="form-group">
                            <Col className="text-right">
                              { (props.forgetStep &&
                                props.forgetStep === "finish" && (
                                  <Link to="login">
                                    <button
                                      className="btn btn-primary w-md waves-effect waves-light"
                                      type="button"
                                    >
                                      Voltar
                                    </button>
                                  </Link>
                                )) || (
                                <button
                                  className="btn btn-primary w-md waves-effect waves-light"
                                  type="submit"
                                  disabled={
                                    (props.forgetStep &&
                                      props.forgetStep === "code" &&
                                      code.length < 6) ||
                                    (!props.forgetStep &&
                                      !recaptchaValidated) ||
                                    (props.forgetStep &&
                                      props.forgetStep === "password" &&
                                      passwordScore < 3)
                                  }
                                >
                                  Enviar
                                </button>
                              ) }
                            </Col>
                          </Row>
                        </AvForm>
                        <div className="mt-5 text-center">
                          <p>
                            Retornar para{ " " }
                            <Link
                              to="login"
                              className="font-weight-medium text-primary"
                            >
                              Login
                            </Link>{ " " }
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © { new Date().getFullYear() } Inforangra.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  forgetStep: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg, forgetStep, loading } =
    state.ForgetPassword
  return { forgetError, forgetSuccessMsg, forgetStep, loading }
}

export default withRouter(
  connect(mapStatetoProps, {
    userForgetPassword,
    userForgetPasswordError,
    userForgetPasswordSuccess
  })(ForgetPasswordPage)
)
